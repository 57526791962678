<template>
  <div id="app">
    <div>
      <input
        v-model="raffleNumber"
        type="text"
        placeholder="Please enter your raffle number"
        style="
          width: 80%;
          height: 30px;
          margin-bottom: 15px;
          border-radius: 10px;
          padding-left: 15px;
        "
      />
    </div>

    <v-lottery
      :data="awardsData"
      v-model="target"
      @onstart="startRotation"
      @onstop="stopRotation"
    ></v-lottery>

    <!-- 模态框 -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <div v-if="modalImage">
          <!-- 显示奖项标题 -->
          <div v-if="prizeTitle" class="modal-title">{{ prizeTitle }}</div>

          <img :src="modalImage" alt="奖品图片" />

          <div class="close" @click="closeModal">
            <button class="confirm-button">Confirm</button>
          </div>
        </div>
        <div v-else>
          <span class="modal-title">{{ errorTips }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import phone from "./images/001.png";
import laptop from "./images/002.png";
import glasses from "./images/003.png";
import tour from "./images/004.png";
import token from "./images/006.png";
import noPrize from "./images/005.png";

import axios from "axios";
export default {
  name: "app",
  data() {
    return {
      awardsData: {
        data: [
          {
            title: "iPhone 16",
            rewardId: 1,
            color: "#fe807d",
            src: phone,
          },
          {
            title: "MacBook Pro",
            rewardId: 2,
            color: "#fe7771",
            src: laptop,
          },
          {
            title: "AI Glasses",
            rewardId: 3,
            color: "#fe807d",
            src: glasses,
          },
          {
            title: "7-Day European Tour",
            rewardId: 4,
            color: "#fe807d",
            src: tour,
          },
          {
            title: "No Prize",
            rewardId: 5,
            color: "#fe7771",
            src: noPrize,
          },
          {
            title: "One AI 6.0 Token",
            rewardId: 6,
            color: "#fe7771",
            src: token,
          },
        ],
        bgColor: "#ff5859",
        width: 400,
        height: 400,
        goImage: require("./static/go.png"),
      },

      errorTips: "",
      target: "",
      raffleNumber: "",
      showModal: false, // 控制模态框显示
      modalImage: "", // 存储模态框中显示的图片
      prizeTitle: "", // 存储提示语
    };
  },
  methods: {
    startRotation() {
      if (!this.raffleNumber) {
        this.errorTips = "The lottery code is required";
        this.showModal = true;

        setTimeout(() => {
          this.showModal = false;
          window.location.reload();
        }, 2000);
        return;
      }
      axios
        .post("https://lottery.ban-capital.com/api/Lottery/submitLottery", {
          code: this.raffleNumber,
        })
        .then((res) => {
          if (res.data && res.data.code && res.data.code == 1) {
            //是六个选项中的一个
            const target = res.data.data.level.level_num;

            setTimeout(() => {
              this.target = target;
              this.raffleNumber = "";
            }, 1000);
          } else {
            this.errorTips = res.data.msg;
            this.showModal = true;

            setTimeout(() => {
              this.showModal = false;
              window.location.reload();
            }, 2000);
            return;
          }
        })
        .catch((error) => {
          console.error("Request failed:", error);
        });
    },

    stopRotation() {
      // 根据获奖的 target 显示相应的图片和标题
      if (this.target) {
        const prize = this.awardsData.data.find(
          (item) => item.rewardId == this.target
        );
        if (prize) {
          this.modalImage = prize.src; // 设置模态框图片为获奖图片
          this.prizeTitle = `Congratulations, you've won：${prize.title}`; // 设置奖项标题
        }
      } else {
        this.modalImage = noPrize; // 如果没有获奖，显示没有奖品的图片
        this.prizeTitle = "We're sorry, but you did not win this time"; // 设置提示语
      }

      this.showModal = true; // 显示模态框
    },

    closeModal() {
      this.showModal = false; // 关闭模态框
      this.prizeTitle = ""; // 清除提示语
      window.location.reload();
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width: 500px;
  height: 500px;
}

@media (max-width: 768px) {
  #app {
    width: 300px;
    height: 300px;
  }
}

/* 模态框样式 */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: -5%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.modal-content {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff; /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.confirm-button:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

img {
  width: 100%;
  max-width: 300px;
  border-radius: 15px;
}

@keyframes sparkle {
  0% {
    font-size: 30px;
    text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px gold, 0 0 40px gold,
      0 0 50px gold;
  }
  50% {
    font-size: 35px;
    text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px gold, 0 0 50px gold,
      0 0 60px gold;
  }
  100% {
    font-size: 30px;
    text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px gold, 0 0 40px gold,
      0 0 50px gold;
  }
}

.modal-title {
  max-width: 400px;
  word-wrap: break-word;
  white-space: normal;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #000;
  animation: sparkle 1.5s 3 ease-in-out forwards; /* 动画闪烁3次，结束后保持最终状态 */
}
</style>
